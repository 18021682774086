import React, { Children, cloneElement, useCallback, useEffect } from "react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

import HostPage from "@/components/pages/HostPage";

import { PUBLIC_URL } from "@/constants";
import { PETROL_DARK } from "@/colors";
import LayoutBackground from "@/components/LayoutBackground";

export async function getServerSideProps(context) {
	const quiz = context?.params?.quiz && context?.params?.quiz.length > 0 ? context?.params?.quiz[0] : null;
	return { props: { quiz } };
}

function Page({ quiz }) {
	const router = useRouter();
	const query = router?.query?.query || null;
	const language = router?.query?.language || null;
	const questions = parseInt(router?.query?.questions) ?? 8;
	const images = router?.query?.images === "true" ? true : router?.query?.images === "false" ? false : true;

	const boostGuardDisabled = router?.query?.boostGuardDisabled || false;
	const quizId = router.query.quiz && router.query.quiz.length > 0 ? router.query.quiz[0] : null;
	const previewSlideIndex = router.query.quiz && router.query.quiz.length > 1 ? parseInt(router.query.quiz[1]) : null;

	let publicUrl = PUBLIC_URL || "";
	if (publicUrl.endsWith("/")) {
		publicUrl = publicUrl.substring(0, publicUrl.length - 1);
	}

	const url = `${publicUrl}/join/${quiz ? `${quiz}/` : ""}`;
	const seo = {
		canonical: url,
		openGraph: {
			url: url,
		},
	};

	return (
		<>
			<LayoutBackground color={PETROL_DARK} />
			<NextSeo {...seo} />
			<HostPage
				quizId={quizId}
				query={query}
				language={language}
				questions={questions}
				images={images}
				boostGuardDisabled={boostGuardDisabled}
				previewSlideIndex={previewSlideIndex}
			/>
		</>
	);
}

Page.getLayoutProps = (_pageProps) => ({ hideTopMenu: true, isHostPage: true, hideCookieConsent: true });

export default Page;
